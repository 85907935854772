var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "remove-pointer-for-spectator";
},"3":function(container,depth0,helpers,partials,data) {
    return "selected-home-view";
},"5":function(container,depth0,helpers,partials,data) {
    return "tabindex=\"0\"";
},"7":function(container,depth0,helpers,partials,data) {
    return "hide";
},"9":function(container,depth0,helpers,partials,data) {
    return "hide_unread_counts";
},"11":function(container,depth0,helpers,partials,data) {
    return "                    <div id=\"subscribe-to-more-streams\"></div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"left-sidebar\" id=\"left-sidebar\" role=\"navigation\">\n    <div id=\"left-sidebar-navigation-area\" class=\"left-sidebar-navigation-area\">\n        <div id=\"views-label-container\" class=\"showing-expanded-navigation "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_spectator"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":75},"end":{"line":3,"column":130}}})) != null ? stack1 : "")
    + "\">\n            <i id=\"toggle-top-left-navigation-area-icon\" class=\"fa fa-sm fa-caret-down views-tooltip-target hidden-for-spectators\" aria-hidden=\"true\"></i><h4 class=\"left-sidebar-title\"><span class=\"views-tooltip-target\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"VIEWS",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":78},"end":{"line":6,"column":92}}}))
    + "</span></h4>\n            <ul id=\"left-sidebar-navigation-list-condensed\" class=\"filters\">\n                <li class=\"top_left_inbox left-sidebar-navigation-condensed-item "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_inbox_home_view"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":81},"end":{"line":8,"column":132}}})) != null ? stack1 : "")
    + "\">\n                    <a href=\"#inbox\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_inbox_home_view"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":37},"end":{"line":9,"column":82}}})) != null ? stack1 : "")
    + " class=\"tippy-views-tooltip left-sidebar-navigation-icon-container\" data-tooltip-template-id=\"inbox-tooltip-template\">\n                        <span class=\"filter-icon\">\n                            <i class=\"zulip-icon zulip-icon-inbox\" aria-hidden=\"true\"></i>\n                        </span>\n                        <span class=\"unread_count\"></span>\n                    </a>\n                </li>\n                <li class=\"top_left_recent_view left-sidebar-navigation-condensed-item "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_recent_view_home_view"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":87},"end":{"line":16,"column":144}}})) != null ? stack1 : "")
    + "\">\n                    <a href=\"#recent\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_recent_view_home_view"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":38},"end":{"line":17,"column":89}}})) != null ? stack1 : "")
    + " class=\"tippy-views-tooltip left-sidebar-navigation-icon-container\" data-tooltip-template-id=\"recent-conversations-tooltip-template\">\n                        <span class=\"filter-icon\">\n                            <i class=\"zulip-icon zulip-icon-recent\" aria-hidden=\"true\"></i>\n                        </span>\n                        <span class=\"unread_count\"></span>\n                    </a>\n                </li>\n                <li class=\"top_left_all_messages left-sidebar-navigation-condensed-item "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_all_messages_home_view"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":88},"end":{"line":24,"column":146}}})) != null ? stack1 : "")
    + "\">\n                    <a href=\"#feed\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_all_messages_home_view"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":36},"end":{"line":25,"column":88}}})) != null ? stack1 : "")
    + " class=\"home-link tippy-views-tooltip left-sidebar-navigation-icon-container\" data-tooltip-template-id=\"all-message-tooltip-template\">\n                        <span class=\"filter-icon\">\n                            <i class=\"zulip-icon zulip-icon-all-messages\" aria-hidden=\"true\"></i>\n                        </span>\n                        <span class=\"unread_count\"></span>\n                    </a>\n                </li>\n                <li class=\"top_left_mentions left-sidebar-navigation-condensed-item\">\n                    <a href=\"#narrow/is/mentioned\" class=\"tippy-left-sidebar-tooltip left-sidebar-navigation-icon-container\" data-tooltip-template-id=\"mentions-tooltip-template\">\n                        <span class=\"filter-icon\">\n                            <i class=\"zulip-icon zulip-icon-at-sign\" aria-hidden=\"true\"></i>\n                        </span>\n                        <span class=\"unread_count\"></span>\n                    </a>\n                </li>\n                <li class=\"top_left_starred_messages left-sidebar-navigation-condensed-item\">\n                    <a href=\"#narrow/is/starred\" class=\"tippy-left-sidebar-tooltip left-sidebar-navigation-icon-container\" data-tooltip-template-id=\"starred-tooltip-template\">\n                        <span class=\"filter-icon\">\n                            <i class=\"zulip-icon zulip-icon-star-filled\" aria-hidden=\"true\"></i>\n                        </span>\n                        <span class=\"unread_count\"></span>\n                    </a>\n                </li>\n            </ul>\n            <div class=\"left-sidebar-navigation-menu-icon\">\n                <i class=\"zulip-icon zulip-icon-more-vertical\"></i>\n            </div>\n        </div>\n        <ul id=\"left-sidebar-navigation-list\" class=\"left-sidebar-navigation-list filters\">\n            <li class=\"top_left_inbox top_left_row hidden-for-spectators "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_inbox_home_view"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":73},"end":{"line":54,"column":124}}})) != null ? stack1 : "")
    + "\">\n                <a href=\"#inbox\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_inbox_home_view"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":33},"end":{"line":55,"column":78}}})) != null ? stack1 : "")
    + " class=\"left-sidebar-navigation-label-container tippy-views-tooltip\" data-tooltip-template-id=\"inbox-tooltip-template\">\n                    <span class=\"filter-icon\">\n                        <i class=\"zulip-icon zulip-icon-inbox\" aria-hidden=\"true\"></i>\n                    </span><span class=\"left-sidebar-navigation-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Inbox",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":60,"column":64},"end":{"line":60,"column":78}}}))
    + "</span>\n                    <span class=\"unread_count\"></span>\n                </a>\n                <span class=\"arrow sidebar-menu-icon inbox-sidebar-menu-icon hidden-for-spectators\"><i class=\"zulip-icon zulip-icon-more-vertical\" aria-hidden=\"true\"></i></span>\n            </li>\n            <li class=\"top_left_recent_view top_left_row "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_recent_view_home_view"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":57},"end":{"line":65,"column":114}}})) != null ? stack1 : "")
    + "\">\n                <a href=\"#recent\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_recent_view_home_view"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":66,"column":34},"end":{"line":66,"column":85}}})) != null ? stack1 : "")
    + " class=\"left-sidebar-navigation-label-container tippy-views-tooltip\" data-tooltip-template-id=\"recent-conversations-tooltip-template\">\n                    <span class=\"filter-icon\">\n                        <i class=\"zulip-icon zulip-icon-recent\" aria-hidden=\"true\"></i>\n                    </span><span class=\"left-sidebar-navigation-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Recent conversations",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":71,"column":64},"end":{"line":71,"column":93}}}))
    + "</span>\n                    <span class=\"unread_count\"></span>\n                </a>\n                <span class=\"arrow sidebar-menu-icon recent-view-sidebar-menu-icon hidden-for-spectators "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_recent_view_home_view"),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":74,"column":105},"end":{"line":74,"column":148}}})) != null ? stack1 : "")
    + "\">\n                    <i class=\"zulip-icon zulip-icon-more-vertical\" aria-hidden=\"true\"></i>\n                </span>\n            </li>\n            <li class=\"top_left_all_messages top_left_row "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_all_messages_home_view"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":78,"column":58},"end":{"line":78,"column":116}}})) != null ? stack1 : "")
    + "\">\n                <a href=\"#feed\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_all_messages_home_view"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":79,"column":32},"end":{"line":79,"column":84}}})) != null ? stack1 : "")
    + " class=\"home-link left-sidebar-navigation-label-container tippy-views-tooltip\" data-tooltip-template-id=\"all-message-tooltip-template\">\n                    <span class=\"filter-icon\">\n                        <i class=\"zulip-icon zulip-icon-all-messages\" aria-hidden=\"true\"></i>\n                    </span><span class=\"left-sidebar-navigation-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Combined feed",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":84,"column":64},"end":{"line":84,"column":86}}}))
    + "</span>\n                    <span class=\"unread_count\"></span>\n                </a>\n                <span class=\"arrow sidebar-menu-icon all-messages-sidebar-menu-icon hidden-for-spectators "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_all_messages_home_view"),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":87,"column":106},"end":{"line":87,"column":150}}})) != null ? stack1 : "")
    + "\">\n                    <i class=\"zulip-icon zulip-icon-more-vertical\" aria-hidden=\"true\"></i>\n                </span>\n            </li>\n            <li class=\"top_left_mentions top_left_row hidden-for-spectators\">\n                <a class=\"left-sidebar-navigation-label-container\" href=\"#narrow/is/mentioned\">\n                    <span class=\"filter-icon\">\n                        <i class=\"zulip-icon zulip-icon-at-sign\" aria-hidden=\"true\"></i>\n                    </span><span class=\"left-sidebar-navigation-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Mentions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":97,"column":64},"end":{"line":97,"column":81}}}))
    + "</span>\n                    <span class=\"unread_count\"></span>\n                </a>\n            </li>\n            <li class=\"top_left_my_reactions top_left_row hidden-for-spectators\">\n                <a class=\"left-sidebar-navigation-label-container tippy-views-tooltip\" href=\"#narrow/has/reaction/sender/me\" data-tooltip-template-id=\"my-reactions-tooltip-template\">\n                    <span class=\"filter-icon\">\n                        <i class=\"zulip-icon zulip-icon-smile\" aria-hidden=\"true\"></i>\n                    </span><span class=\"left-sidebar-navigation-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Reactions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":107,"column":64},"end":{"line":107,"column":82}}}))
    + "</span>\n                    <span class=\"unread_count\"></span>\n                </a>\n            </li>\n            <li class=\"top_left_starred_messages top_left_row hidden-for-spectators\">\n                <a class=\"left-sidebar-navigation-label-container\" href=\"#narrow/is/starred\">\n                    <span class=\"filter-icon\">\n                        <i class=\"zulip-icon zulip-icon-star-filled\" aria-hidden=\"true\"></i>\n                    </span><span class=\"left-sidebar-navigation-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Starred messages",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":117,"column":64},"end":{"line":117,"column":89}}}))
    + "</span>\n                    <span class=\"unread_count\"></span>\n                </a>\n                <span class=\"arrow sidebar-menu-icon starred-messages-sidebar-menu-icon\"><i class=\"zulip-icon zulip-icon-more-vertical\" aria-hidden=\"true\"></i></span>\n            </li>\n            <li class=\"top_left_drafts top_left_row hidden-for-spectators\">\n                <a href=\"#drafts\" class=\"left-sidebar-navigation-label-container tippy-views-tooltip\" data-tooltip-template-id=\"drafts-tooltip-template\">\n                    <span class=\"filter-icon\">\n                        <i class=\"zulip-icon zulip-icon-drafts\" aria-hidden=\"true\"></i>\n                    </span><span class=\"left-sidebar-navigation-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Drafts",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":128,"column":64},"end":{"line":128,"column":79}}}))
    + "</span>\n                    <span class=\"unread_count\"></span>\n                </a>\n                <span class=\"arrow sidebar-menu-icon drafts-sidebar-menu-icon\"><i class=\"zulip-icon zulip-icon-more-vertical\" aria-hidden=\"true\"></i></span>\n            </li>\n            <li class=\"top_left_scheduled_messages top_left_row hidden-for-spectators\">\n                <a class=\"left-sidebar-navigation-label-container\" href=\"#scheduled\">\n                    <span class=\"filter-icon\">\n                        <i class=\"zulip-icon zulip-icon-scheduled-messages\" aria-hidden=\"true\"></i>\n                    </span><span class=\"left-sidebar-navigation-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Scheduled messages",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":139,"column":64},"end":{"line":139,"column":91}}}))
    + "</span>\n                    <span class=\"unread_count\"></span>\n                </a>\n            </li>\n        </ul>\n    </div>\n\n    <div id=\"direct-messages-section-header\" class=\"direct-messages-container hidden-for-spectators zoom-out zoom-in-sticky\">\n        <i id=\"toggle-direct-messages-section-icon\" class=\"fa fa-sm fa-caret-down dm-tooltip-target zoom-in-hide\" aria-hidden=\"true\" tabindex=\"0\" role=\"button\"></i>\n        <h4 class=\"left-sidebar-title\"><span class=\"dm-tooltip-target\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"DIRECT MESSAGES",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":148,"column":71},"end":{"line":148,"column":95}}}))
    + "</span></h4>\n        <div class=\"heading-markers-and-controls\">\n            <a id=\"show-all-direct-messages\" href=\"#narrow/is/dm\" data-tooltip-template-id=\"show-all-direct-messages-template\">\n                <i class=\"fa fa-align-right\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Direct message feed",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":151,"column":57},"end":{"line":151,"column":85}}}))
    + "\"></i>\n            </a>\n            <span class=\"unread_count\"></span>\n        </div>\n        <a class=\"zoom-out-hide\" id=\"hide-more-direct-messages\">\n            <span class=\"hide-more-direct-messages-text\"> "
    + alias2(lookupProperty(helpers,"t").call(alias1,"back to channels",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":156,"column":58},"end":{"line":156,"column":83}}}))
    + "</span>\n        </a>\n        <div class=\"zoom-out-hide direct-messages-search-section\">\n            <input class=\"direct-messages-list-filter filter_text_input\" type=\"text\" autocomplete=\"off\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter direct messages",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":159,"column":117},"end":{"line":159,"column":148}}}))
    + "\" />\n            <button type=\"button\" class=\"btn clear_search_button\" id=\"clear-direct-messages-search-button\">\n                <i class=\"fa fa-remove\" aria-hidden=\"true\"></i>\n            </button>\n        </div>\n    </div><div id=\"left_sidebar_scroll_container\" class=\"scrolling_list\" data-simplebar data-simplebar-tab-index=\"-1\">\n        <div class=\"direct-messages-container zoom-out hidden-for-spectators\">\n            <div id=\"direct-messages-list\"></div>\n        </div>\n\n        <div id=\"streams_list\" class=\"zoom-out\">\n            <div id=\"streams_header\" class=\"zoom-in-hide "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"hide_unread_counts"),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":172,"column":57},"end":{"line":172,"column":108}}})) != null ? stack1 : "")
    + "\">\n                <h4 class=\"left-sidebar-title\"><span class=\"streams-tooltip-target\" data-tooltip-template-id=\"filter-streams-tooltip-template\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"CHANNELS",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":173,"column":143},"end":{"line":173,"column":160}}}))
    + "</span></h4>\n                <div class=\"heading-markers-and-controls\">\n                    <i id=\"filter_streams_tooltip\" class=\"streams_filter_icon fa fa-filter\" aria-hidden=\"true\" data-tooltip-template-id=\"filter-streams-tooltip-template\"></i>\n                    <span id=\"add_streams_tooltip\" class=\"hidden-for-spectators\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add channels",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":176,"column":101},"end":{"line":176,"column":122}}}))
    + "\">\n                        <i id=\"streams_inline_icon\" class='fa fa-plus' aria-hidden=\"true\" ></i>\n                    </span>\n                    <span class=\"unread_count\"></span>\n                    <span class=\"masked_unread_count\"></span>\n                </div>\n\n                <div class=\"notdisplayed stream_search_section\">\n                    <input class=\"stream-list-filter home-page-input filter_text_input\" type=\"text\" autocomplete=\"off\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter channels",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":184,"column":132},"end":{"line":184,"column":156}}}))
    + "\" />\n                    <button type=\"button\" class=\"btn clear_search_button\" id=\"clear_search_stream_button\">\n                        <i class=\"fa fa-remove\" aria-hidden=\"true\"></i>\n                    </button>\n                </div>\n            </div>\n            <div id=\"topics_header\">\n                <a class=\"show-all-streams\" tabindex=\"0\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Back to channels",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":191,"column":57},"end":{"line":191,"column":82}}}))
    + "</a> <span class=\"unread_count\"></span>\n            </div>\n            <div id=\"stream-filters-container\">\n                <ul id=\"stream_filters\" class=\"filters\"></ul>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"is_guest"),{"name":"unless","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":195,"column":16},"end":{"line":197,"column":27}}})) != null ? stack1 : "")
    + "                <div class=\"only-visible-for-spectators\">\n                    <div id=\"login-link-container\" class=\"login_button\">\n                        <i class=\"zulip-icon zulip-icon-log-in\" aria-hidden=\"true\"></i><a class=\"login-text\">\n                            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Log in to browse more channels",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":203,"column":28},"end":{"line":203,"column":66}}}))
    + "\n                        </a>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});